import React from 'react';
import { MaskWrapper } from './styles';

const Mask = ({ x, y }) => {
  return (
    <MaskWrapper className={'MaskWrapper'} x={x} y={y}>
      <svg
        width="305"
        height="230"
        viewBox="0 0 305 230"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="305"
          height="230"
        >
          <rect width="305" height="230" rx="5" fill="white" />
        </mask>
        <g mask="url(#mask0)">
          <g opacity="0.5" filter="url(#filter0_f)">
            <ellipse
              cx="244.455"
              cy="195.5"
              rx="139.754"
              ry="112.5"
              fill="#CCFF00"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_f"
            x="4.70154"
            y="-17"
            width="479.507"
            height="425"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur" />
          </filter>
        </defs>
      </svg>
    </MaskWrapper>
  );
};

export default Mask;
