import React from 'react';
import { graphql } from 'gatsby';
import ParentResources from 'components/Layouts/ParentResources';

const ParentResourcesTemplate = (props) => <ParentResources {...props} />;

export default ParentResourcesTemplate;

export const parentResourcesQuery = graphql`
  query($handle: String!) {
    contentfulParentResourcesPage(handle: { eq: $handle }) {
      handle
      id
      contentful_id
      title
      resources {
        excerpt
        handle
        parent_resources___page {
          handle
        }
        id
        contentful_id
        title
        relatedResources {
          id
          contentful_id
          excerpt
          title
          handle
          parentResource {
            __typename
            ... on ContentfulParentResourcesPage {
              id
              handle
              link___module {
                internalLink {
                  ... on ContentfulParentResourcesPage {
                    id
                    handle
                  }
                  ... on ContentfulGenericPage {
                    id
                    handle
                  }
                  ... on ContentfulBlogPage {
                    id
                    handle
                  }
                }
              }
            }
            ... on ContentfulResourcePage {
              id
              handle
              parent_resources___page {
                handle
              }
            }
          }
        }
      }
    }
  }
`;
