import styled from 'styled-components';

export const CardLink = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};

  .Site-Link {
    position: relative;
    z-index: 2;
    padding: 15px;
    max-width: 100%;
    height: 100%;
    min-height: 63px;
    /* display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    align-items: start; */
    display: flex;

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 0 26px;
      padding: 30px;
      min-height: 131px;
      align-items: end;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      min-height: 170px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
      font-weight: ${(props) =>
        props.theme.fontWeight.small_link_large_desktop};
      font-size: ${(props) => props.theme.fonts.small_link_large_desktop};
      line-height: ${(props) =>
        props.theme.lineHeight.small_link_large_desktop};
    }

    .Icon {
      display: none;

      @media (min-width: ${(props) => props.theme.breakpoints.s}) {
        display: block;
        margin-bottom: 5px;
        align-self: end;
        width: 36px;
        height: 36px;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
        font-weight: ${(props) =>
          props.theme.fontWeight.small_link_large_desktop};
        font-size: ${(props) => props.theme.fonts.small_link_large_desktop};
        line-height: ${(props) =>
          props.theme.lineHeight.small_link_large_desktop};
      }
    }
  }

  #site-link-line {
    display: none !important;
  }

  .Site-Link-Body {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-weight: 400;
    font-size: 16px;
    line-height: ${(props) => props.theme.lineHeight.small_link_mobile};

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-family: ${(props) => props.theme.fontFamily.secondary};
      font-weight: ${(props) => props.theme.fontWeight.small_link_tablet};
      font-size: ${(props) => props.theme.fonts.small_link_tablet};
      line-height: ${(props) => props.theme.lineHeight.small_link_tablet};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-weight: ${(props) => props.theme.fontWeight.small_link_desktop};
      font-size: ${(props) => props.theme.fonts.small_link_desktop};
      line-height: ${(props) => props.theme.lineHeight.small_link_desktop};
    }
  }
`;

export const MaskWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;

  svg {
    width: 100%;
    height: auto;
  }
`;
